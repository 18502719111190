.wrapper {
  display: flex;
  height: 100vh;
}

.MainView {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.MinTextPart {
  padding: 20px;
  background: #fcffff;
  box-shadow: 0px 4px 15px rgba(86, 160, 164, 0.34);
  border-radius: 15px;
  min-width: 35vw;
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.FailedText {
  color: red;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 10px;
}

.SuccessText {
  color: #1a6b6b;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 10px;
}

.PenndingText {
  color: #91d4dd;
  font-weight: bold;
  font-size: 22px;
}

.GoMain {
  background-color: #91d4dd;
  box-shadow: 0px 4px 15px rgba(86, 160, 164, 0.34);
  padding: 10px 20px;
  margin-top: 15px;
  border-radius: 15px;
  color: #fcffff;
  font-weight: bold;
  cursor: pointer;
}
