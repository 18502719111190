html {
  scroll-behavior: smooth;
}
div {
  padding: 0;
}
p {
  margin-block-start: 0;
  margin-block-end: 0;
}
body {
  margin: 0px;
  padding: 0px;
  position: relative;
  padding-bottom: 100;
  background: linear-gradient(
      180deg,
      rgba(227, 254, 255, 0.3) 0%,
      rgba(128, 224, 228, 0.16875) 45.31%,
      rgba(115, 255, 129, 0.039) 100%
    ),
    #ffffff;
}
button {
  border-style: none;
  background-color: transparent;
}
a:-webkit-any-link {
  text-decoration: none;
  cursor: default;
}
@font-face {
  font-family: 'FontThin';
  src: local('FontThin'), url(./fonts/e-Ukraine-Thin.otf) format('opentype');
}
@font-face {
  font-family: 'FontRegular';
  src: local('FontRegular'),
    url(./fonts/e-Ukraine-Regular.otf) format('opentype');
}
@font-face {
  font-family: 'FontBold';
  src: local('FontBold'), url(./fonts/e-Ukraine-Bold.otf) format('opentype');
}
@font-face {
  font-family: 'FontRegularHead';
  src: local('FontRegularHead'),
    url(./fonts/e-UkraineHead-Regular.otf) format('opentype');
}
@font-face {
  font-family: 'FontThinHead';
  src: local('FontThinHead'),
    url(./fonts/e-UkraineHead-Thin.otf) format('opentype');
}
.backgroundView {
  display: flex;
  flex-direction: row;
}
.mainBody {
  height: 300;
  flex: 1;
  align-self: flex-end;
}
.TopBar {
  z-index: 9999;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
}
.TitleAbsolute {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 255px;
  text-align: center;
}
.topBarButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: 1%;
  padding-right: 1%;
}

.TopBarButton {
  display: flex;
  flex-direction: row;
}

.TextLinks {
  font-family: 'FontThin';
  font-weight: bold;
  font-size: 14px;
  color: rgba(26, 46, 74, 1);
  cursor: pointer;
}

.TextLinksTitle {
  font-family: 'FontRegularHead';
  font-weight: bold;
  font-size: 18px;
  color: rgba(26, 46, 74, 1);
}

@media screen and (min-width: 601px) {
  a.Text {
    font-family: 'FontThin';
    font-weight: bold;
    font-size: 22px;
    color: rgba(26, 46, 74, 1);
  }
  .MarginTop {
    margin-top: 100px;
  }
  .HeaderH1 {
    font-family: 'FontRegularHead';
    text-align: center;
    font-size: 3.5vw;
    line-height: 125%;
    color: rgba(26, 46, 74, 1);
  }
  .HeaderH2 {
    font-family: 'FontThinHead';
    text-align: center;
    font-size: 1.3vw;
    color: rgba(26, 46, 74, 1);
    margin-top: 24px;
    line-height: 200%;
  }
  .LanguagesText {
    width: 40px;
    font-family: 'FontThin';
    font-size: 1.2vw;
    text-align: center;
    color: rgba(26, 46, 74, 1);
  }
  .PhoneImage {
    width: 30%;
    height: auto;
  }
  .TabletImage {
    width: 75%;
    height: auto;
  }
  .Icons {
    width: 100%;
    height: auto;
  }
  .TextHC {
    font-family: 'FontThin';
    font-weight: bold;
    font-size: 1vw;
    color: rgba(26, 46, 74, 1);
  }

  .Logo {
    width: 100%;
    height: auto;
  }
  .CountDownText {
    font-family: 'FontRegular';
    font-size: 50px;
    display: flex;
    align-items: center;
    text-align: center;
    margin-right: 10px;
    margin-left: 10px;
    color: #1a6b6b;
  }
}

@media screen and (max-width: 600px) {
  a.Text {
    font-family: 'FontThin';
    font-weight: bold;
    font-size: 12px;
    color: rgba(26, 46, 74, 1);
  }
  .MarginTop {
    margin-top: 0px;
  }
  .HeaderH1 {
    font-family: 'FontRegularHead';
    text-align: center;
    font-size: 5vw;
    line-height: 160%;

    color: rgba(26, 46, 74, 1);
  }
  .HeaderH2 {
    font-family: 'FontThinHead';
    text-align: center;
    font-size: 3vw;
    color: rgba(26, 46, 74, 1);
    margin-top: 24px;
    line-height: 150%;
  }
  .LanguagesText {
    width: 40px;
    font-family: 'FontThin';
    font-size: 3vw;
    text-align: center;
    color: rgba(26, 46, 74, 1);
  }
  .PhoneImage {
    width: 75%;
    height: auto;
  }
  .TabletImage {
    width: 99%;
    height: auto;
  }
  .Logo {
    width: 50%;
    height: auto;
  }
  .Icons {
    width: 100%;
    height: auto;
  }
  .TextHC {
    font-family: 'FontThin';
    font-weight: bold;
    font-size: 7px;
    line-height: 0px;
    color: rgba(26, 46, 74, 1);
  }
  .CountDownText {
    font-family: 'FontRegular';
    font-size: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    margin-right: 10px;
    margin-left: 10px;
    color: #1a6b6b;
  }
}

@media screen and (min-width: 1001px) {
  .BackLine {
    visibility: visible;
  }
  .ColumnControl {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-bottom: 100px;
  }

  .ImageMarginLeft {
    margin-left: 10%;
  }
  .ImageMarginRight {
    margin-right: 10%;
  }
  .ImageMarginMiddle {
    margin-right: 5%;
  }
  .MarginLeft {
    margin-left: 10%;
  }
  .stepImages {
    width: 40%;
  }
  .StepTitle {
    font-family: 'FontRegularHead';
    font-size: 1.6vw;
    color: rgba(5, 9, 15, 1);
    line-height: 225%;
  }
  .StepBody {
    font-family: 'FontThinHead';
    font-size: 1vw;
    color: rgba(5, 9, 15, 1);
    line-height: 200%;
  }
  .SubmitButton {
    font-family: 'FontThinHead';
    font-size: 18px;
    color: rgba(255, 255, 255, 1);
    border-radius: 3px;
    margin-top: 70px;
    width: 526px;
    height: 60px;
  }
  .formText {
    font-family: 'FontThinHead';
    font-size: 18px;
    color: rgba(26, 46, 74, 0.5);
    width: 90%;
    height: 60px;
    border-width: 0px;
    margin-bottom: 8px;
    margin-top: 8px;
    padding-left: 24px;
  }
  .Title {
    font-family: 'FontRegularHead';
    /* margin-top: 24px; */
    text-align: center;
    font-size: 2vw;
    color: rgba(26, 46, 74, 1);
  }
  .SubscribeText {
    font-family: 'FontRegularHead';
    font-size: 1.2vw;
    color: rgba(5, 9, 15, 1);
  }
  .HCProfessional {
    width: 60%;
    padding: 10px;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    background-color: #fdffff;
    border-radius: 10px;
    box-shadow: 0px 0px 100px rgba(86, 160, 164, 0.3);
    backdrop-filter: blur(100px);
  }
  .OurPartner {
    width: 240px;
    margin-right: 30px;
    margin-left: 30px;
    border-radius: 15px;
    filter: drop-shadow(0px 0px 15px rgba(26, 107, 107, 0.4));
    overflow: hidden;
  }
}
@media screen and (max-width: 1000px) {
  .BackLine {
    visibility: hidden;
  }
  .ColumnControl {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 50px;
  }
  .Reverse {
    flex-direction: column-reverse;
  }
  .Step {
    width: 70%;
  }
  .MarginLeft {
    margin-left: 0px;
  }
  .stepImages {
    width: 80%;
  }
  .StepTitle {
    font-family: 'FontRegularHead';
    font-size: 3.2vw;
    color: rgba(5, 9, 15, 1);
    line-height: 200%;
    text-align: center;
  }
  .StepBody {
    font-family: 'FontThinHead';
    font-size: 2.5vw;
    color: rgba(5, 9, 15, 1);
    line-height: 175%;
    text-align: center;
  }
  .SubmitButton {
    font-family: 'FontThinHead';
    font-size: 18px;
    color: rgba(255, 255, 255, 1);
    border-radius: 3px;
    margin-top: 70px;
    width: 50%;
    height: 60px;
  }
  .formText {
    font-family: 'FontThinHead';
    font-size: 18px;
    color: rgba(26, 46, 74, 0.5);
    width: 100%;
    height: 60px;
    border-width: 0px;
    margin-bottom: 8px;
    margin-top: 8px;
    padding-left: 24px;
    box-shadow: 0px 4px 15px rgba(86, 160, 164, 0.14);
    backdrop-filter: blur(100px);
    border-radius: 14px;
  }
  .Title {
    font-family: 'FontRegularHead';
    margin-bottom: 24px;
    text-align: center;
    font-size: 5vw;
    color: rgba(26, 46, 74, 1);
  }
  .SubscribeText {
    font-family: 'FontRegularHead';
    font-size: 3vw;
    color: rgba(5, 9, 15, 1);
  }
  .HCProfessional {
    width: 70px;
    height: 40px;
    padding: 0px;
    text-align: center;
    margin-right: 10px;
    background-color: #fdffff;
    border-radius: 10px;
    box-shadow: 0px 0px 100px rgba(86, 160, 164, 0.3);
    backdrop-filter: blur(100px);
  }
  .OurPartner {
    width: 80px;
    margin-right: 5px;
    margin-left: 5x;
    border-radius: 5px;
    filter: drop-shadow(0px 0px 10px rgba(26, 107, 107, 0.4));
    overflow: hidden;
  }
}
@media screen and (min-width: 320px) and (max-width: 375px) {
  .OurPartner {
    width: 65px;
  }
}
.Header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 10vw;
  margin-left: 10vw;
}
.bodyView {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin-top: 100px; */
}
.scroll {
  overflow-y: auto;
  background: linear-gradient(
      90deg,
      rgba(227, 254, 255, 0.3) 0%,
      rgba(128, 224, 228, 0.16875) 45.31%,
      rgba(115, 255, 129, 0.039) 100%
    ),
    #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.HideBar::-webkit-scrollbar {
  display: flex;
}

.dragscroll {
  overflow: auto;
}

.horizontal.dragscroll {
  margin-top: 10px;
  width: 700px;
  margin-left: auto;
  background-color: #000000;
  margin-right: auto;
}

.SubscribeInputText {
  font-family: 'FontRegularHead';
  font-size: 16px;
  color: rgba(5, 9, 15, 1);
}

.LeftSide {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 25px;
  margin-left: 25px;
}
.PlusMargin {
  margin-right: 50px;
}

.ContactForm {
  display: flex;
  height: 550px;
  width: 100%;
  justify-content: center;
}

.Heiht150 {
  height: 150px;
}

.MailAdressText {
  font-family: 'FontRegularHead';
  text-align: start;
  font-size: 16px;
  margin-top: 5px;
  color: #1a6b6b;
}

.CountDownMain {
  background: linear-gradient(0deg, #91d4dd 3.95%, #1a6b6b 113.67%);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CountDownMain2 {
  background: linear-gradient(36deg, #91d4dd 3.95%, #1a6b6b 113.67%);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.CountDownMain3 {
  background: linear-gradient(72deg, #91d4dd 3.95%, #1a6b6b 113.67%);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.CountDownMain4 {
  background: linear-gradient(108deg, #91d4dd 3.95%, #1a6b6b 113.67%);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.CountDownMain5 {
  background: linear-gradient(144deg, #91d4dd 3.95%, #1a6b6b 113.67%);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.CountDownMain6 {
  background: linear-gradient(180deg, #91d4dd 3.95%, #1a6b6b 113.67%);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.CountDownMain7 {
  background: linear-gradient(216deg, #91d4dd 3.95%, #1a6b6b 113.67%);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.CountDownMain8 {
  background: linear-gradient(252deg, #91d4dd 3.95%, #1a6b6b 113.67%);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.CountDownMain9 {
  background: linear-gradient(288deg, #91d4dd 3.95%, #1a6b6b 113.67%);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.CountDownMain10 {
  background: linear-gradient(324deg, #91d4dd 3.95%, #1a6b6b 113.67%);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.RowField {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.CountDownField {
  display: flex;
  flex-direction: row;
  margin-bottom: 50px;
}

.TitleCountDown {
  position: absolute;
  top: 100px;
  font-family: 'FontBold';
  font-size: 40px;
  color: #002028;
  text-align: center;
  line-height: 70px;
  border-bottom: 5px solid #002028;
}
.GoSite {
  font-family: 'FontBold';
  position: absolute;
  font-size: 20px;
  right: 50px;
  margin-top: 100px;
  color: #1a6b6b;
}
.GreenInactive {
  color: #91d4dd;
}

.Black {
  color: #757678;
}
.NavyBlue {
  color: #1b3d79;
}
.video {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}
.containerPrivacy {
  display: flex;
  flex-direction: row;
  margin-left: 150px;
  margin-right: 50px;
  margin-bottom: 150px;
  margin-top: 50px;
}
.documentationContainer {
  display: flex;
  align-items: center;
  margin-top: 25px;
  cursor: pointer;
  width: 350px;
}
.newText {
  display: flex;
  justify-content: center;
  white-space: pre-wrap;
  font-size: 14px;
  line-height: 2;
}
.controlText {
  display: flex;
  flex: 10;
  justify-content: center;
  display: 'flex';
}
@media screen and (max-width: 768px) {
  .containerPrivacy {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 150px;
    margin-top: 50px;
    gap: 50px;
  }
  .documentationContainer {
    display: flex;
    align-items: center;
    margin-top: 25px;
    cursor: pointer;
    width: 300px;
  }
  .newText {
    font-size: 12px;
  }
}
@media screen and (max-width: 768px) {
  .controlText {
    width: 290px;
  }
}
